import * as React from 'react';
import useTheme from '@lobox/uikit/utils/useTheme';

function EmptySearchSvg(props: any) {
  const { theme } = useTheme();
  const { isDark } = theme;

  return isDark ? (
    <svg
      width="280"
      height="160"
      viewBox="0 0 280 160"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M45.3678 144.262C45.3678 144.262 -1.06674 124.455 0.0187389 84.3492C1.10422 44.2434 44.6441 37.9691 74.4346 62.697C104.346 87.4248 119.659 75.3273 135.097 54.4132C150.535 33.4991 174.299 -13.824 217.598 3.89149C249.921 17.0551 271.51 52.363 278.505 84.2262C283.33 106.124 276.334 129.253 259.57 143.647C258.967 144.139 258.484 144.631 257.881 145L45.3678 144.262Z"
        fill="url(#paint0_linear_314_424598)"
      />
      <path
        d="M141.663 160C185.46 160 220.964 157.914 220.964 155.341C220.964 152.768 185.46 150.682 141.663 150.682C97.8658 150.682 62.3613 152.768 62.3613 155.341C62.3613 157.914 97.8658 160 141.663 160Z"
        fill="#171717"
      />
      <path
        d="M92.7313 63.9792C92.3713 64.8024 91.9746 65.6914 91.627 66.5598C91.2793 67.4282 90.9194 68.3213 90.6045 69.2103C89.9583 70.9434 89.4402 72.7221 89.0543 74.532L88.9357 75.1905L88.8948 75.4375C88.8948 75.5074 88.8948 75.598 88.8703 75.6803C88.8547 76.081 88.8629 76.4823 88.8948 76.8821C88.9725 77.7505 89.1116 78.6807 89.3038 79.6108C89.6474 81.4753 90.085 83.3973 90.5308 85.2906L88.4327 86.1137C87.4546 84.3196 86.6216 82.4491 85.9418 80.5204C85.5837 79.5166 85.294 78.4894 85.0747 77.446C84.9519 76.8686 84.8753 76.2823 84.8457 75.6926C84.8457 75.528 84.8457 75.3716 84.8457 75.1946C84.8457 75.0177 84.8457 74.8201 84.8743 74.7049L84.9561 73.8817C85.0783 72.8216 85.2655 71.7701 85.5165 70.7332C85.7455 69.7042 86.04 68.7041 86.3345 67.7163C86.629 66.7285 86.9971 65.7572 87.3856 64.8024C87.5778 64.3249 87.7946 63.8516 87.9828 63.3783C88.1709 62.905 88.3918 62.4523 88.6535 61.946L92.7313 63.9792Z"
        fill="#E4897B"
      />
      <path
        d="M90.4201 84.7349L92.0152 86.303L88.5346 87.9493C88.5346 87.9493 87.6921 86.4635 88.2769 85.1217L90.4201 84.7349Z"
        fill="#E4897B"
      />
      <path
        d="M93.3039 88.6366L90.3632 89.8466L88.5391 87.9328L92.0156 86.303L93.3039 88.6366Z"
        fill="#E4897B"
      />
      <path
        d="M123.01 46.1744L122.621 46.0427C123.294 44.0445 124.14 42.1095 125.149 40.2601L125.505 40.4576C124.509 42.2864 123.674 44.1993 123.01 46.1744ZM127.975 36.5682L127.644 36.3254C131.005 31.7519 135.404 28.0533 140.474 25.538L140.658 25.9084C135.646 28.3978 131.297 32.0568 127.975 36.5806V36.5682ZM172.798 26.4723C171.461 25.7509 170.078 25.1198 168.658 24.5832L168.806 24.1963C170.239 24.7455 171.636 25.389 172.986 26.1225L172.798 26.4723Z"
        fill="#5384EE"
      />
      <path
        opacity="0.3"
        d="M123.01 46.1744L122.621 46.0427C123.294 44.0445 124.14 42.1095 125.149 40.2601L125.505 40.4576C124.509 42.2864 123.674 44.1993 123.01 46.1744ZM127.975 36.5682L127.644 36.3254C131.005 31.7519 135.404 28.0533 140.474 25.538L140.658 25.9084C135.646 28.3978 131.297 32.0568 127.975 36.5806V36.5682ZM172.798 26.4723C171.461 25.7509 170.078 25.1198 168.658 24.5832L168.806 24.1963C170.239 24.7455 171.636 25.389 172.986 26.1225L172.798 26.4723Z"
        fill="black"
      />
      <path
        d="M186.601 42.3632C182.813 34.459 176.149 28.3245 167.988 25.2314C159.828 22.1383 150.797 22.3238 142.769 25.7495C134.741 29.1752 128.331 35.5783 124.867 43.6315C121.404 51.6846 121.153 60.7701 124.166 69.0044C121.434 69.9675 118.735 71.0006 116.052 72.0666C112.182 73.6113 108.349 75.2247 104.551 76.9067C100.755 78.5983 96.9758 80.3228 93.2334 82.1255C91.3561 83.0103 89.5033 83.9446 87.6342 84.8501C86.6618 85.2705 85.7808 85.8788 85.0411 86.6404C84.6408 87.0622 84.2882 87.5274 83.9899 88.0274C83.626 88.6189 83.3504 89.2609 83.1719 89.933C82.8642 91.1442 82.9973 92.4262 83.5471 93.5472C84.097 94.6682 85.0274 95.5542 86.1699 96.0449C86.8044 96.3127 87.4738 96.4873 88.1577 96.5635C88.7353 96.6293 89.3184 96.6293 89.8959 96.5635C90.9455 96.4353 91.9599 96.1011 92.8817 95.5798C94.7344 94.6373 96.5995 93.7278 98.44 92.7606C102.121 90.8714 105.802 88.9 109.438 86.8997C113.074 84.8995 116.714 82.8046 120.264 80.6561C122.718 79.1621 125.197 77.6352 127.626 76.0383C132.292 83.4493 139.611 88.7702 148.069 90.8984C156.527 93.0266 165.474 91.7989 173.056 87.4698C180.639 83.1406 186.276 76.0418 188.799 67.6444C191.322 59.247 190.538 50.1947 186.609 42.3632H186.601ZM168.007 81.681C163.254 84.0419 157.912 84.9315 152.656 84.2374C147.401 83.5432 142.468 81.2965 138.481 77.7812C134.494 74.266 131.633 69.6401 130.259 64.4885C128.885 59.3369 129.059 53.8909 130.761 48.8392C132.462 43.7874 135.614 39.3568 139.817 36.1075C144.021 32.8582 149.088 30.9361 154.377 30.5844C159.666 30.2327 164.94 31.467 169.532 34.1313C174.124 36.7957 177.828 40.7704 180.175 45.5529C181.734 48.729 182.656 52.183 182.888 55.7177C183.12 59.2523 182.658 62.7983 181.528 66.1532C180.398 69.5082 178.622 72.6062 176.303 75.2705C173.983 77.9348 171.164 80.1131 168.007 81.681Z"
        fill="#5384EE"
      />
      <path
        d="M186.601 42.3632C182.813 34.459 176.149 28.3245 167.988 25.2314C159.828 22.1383 150.797 22.3238 142.769 25.7495C134.741 29.1752 128.331 35.5783 124.867 43.6315C121.404 51.6846 121.153 60.7701 124.166 69.0044C121.434 69.9675 118.735 71.0006 116.052 72.0666C112.182 73.6113 108.349 75.2247 104.551 76.9067C100.755 78.5983 96.9758 80.3228 93.2334 82.1255C91.3561 83.0103 89.5033 83.9446 87.6342 84.8501C86.6618 85.2705 85.7808 85.8788 85.0411 86.6404C84.6408 87.0622 84.2882 87.5274 83.9899 88.0274C83.626 88.6189 83.3504 89.2609 83.1719 89.933C82.8642 91.1442 82.9973 92.4262 83.5471 93.5472C84.097 94.6682 85.0274 95.5542 86.1699 96.0449C86.8044 96.3127 87.4738 96.4873 88.1577 96.5635C88.7353 96.6293 89.3184 96.6293 89.8959 96.5635C90.9455 96.4353 91.9599 96.1011 92.8817 95.5798C94.7344 94.6373 96.5995 93.7278 98.44 92.7606C102.121 90.8714 105.802 88.9 109.438 86.8997C113.074 84.8995 116.714 82.8046 120.264 80.6561C122.718 79.1621 125.197 77.6352 127.626 76.0383C132.292 83.4493 139.611 88.7702 148.069 90.8984C156.527 93.0266 165.474 91.7989 173.056 87.4698C180.639 83.1406 186.276 76.0418 188.799 67.6444C191.322 59.247 190.538 50.1947 186.609 42.3632H186.601ZM168.007 81.681C163.254 84.0419 157.912 84.9315 152.656 84.2374C147.401 83.5432 142.468 81.2965 138.481 77.7812C134.494 74.266 131.633 69.6401 130.259 64.4885C128.885 59.3369 129.059 53.8909 130.761 48.8392C132.462 43.7874 135.614 39.3568 139.817 36.1075C144.021 32.8582 149.088 30.9361 154.377 30.5844C159.666 30.2327 164.94 31.467 169.532 34.1313C174.124 36.7957 177.828 40.7704 180.175 45.5529C181.734 48.729 182.656 52.183 182.888 55.7177C183.12 59.2523 182.658 62.7983 181.528 66.1532C180.398 69.5082 178.622 72.6062 176.303 75.2705C173.983 77.9348 171.164 80.1131 168.007 81.681Z"
        fill="url(#paint1_linear_314_424598)"
      />
      <path
        d="M95.7727 51.1133C96.1817 53.3234 96.6316 57.3775 95.0938 58.855C95.0938 58.855 95.695 61.0981 99.7809 61.0981C104.28 61.0981 101.924 58.855 101.924 58.855C99.4701 58.2623 99.5396 56.4349 99.965 54.7146L95.7727 51.1133Z"
        fill="#E4897B"
      />
      <path
        d="M93.2571 60.0197C93.7438 59.2583 92.9258 57.4103 92.9258 57.4103C92.9258 57.4103 100.157 55.5788 103.114 58.3898C103.728 58.9702 103.049 59.8262 103.049 59.8262L93.2571 60.0197Z"
        fill="#5384EE"
      />
      <path
        opacity="0.1"
        d="M93.2571 60.0197C93.7438 59.2583 92.9258 57.4103 92.9258 57.4103C92.9258 57.4103 100.157 55.5788 103.114 58.3898C103.728 58.9702 103.049 59.8262 103.049 59.8262L93.2571 60.0197Z"
        fill="black"
      />
      <path
        d="M81.2949 143.993C80.9145 143.993 80.3051 143.355 80.1252 143.117C80.1174 143.105 80.1133 143.092 80.1133 143.078C80.1133 143.064 80.1174 143.05 80.1252 143.039C80.1318 143.027 80.1413 143.017 80.1528 143.011C80.1643 143.004 80.1774 143.001 80.1906 143.001C80.2356 143.001 81.2376 143.092 81.5117 143.45C81.5415 143.488 81.5627 143.532 81.574 143.579C81.5853 143.626 81.5864 143.674 81.5771 143.722C81.5735 143.783 81.5512 143.842 81.5131 143.89C81.475 143.937 81.423 143.972 81.3644 143.989L81.2949 143.993ZM80.346 143.17C80.6446 143.516 81.1231 143.907 81.3276 143.858C81.3276 143.858 81.4094 143.837 81.434 143.705C81.4393 143.678 81.4385 143.649 81.4314 143.622C81.4243 143.595 81.4112 143.57 81.3931 143.549C81.0901 143.318 80.7255 143.183 80.346 143.162V143.17Z"
        fill="#5384EE"
      />
      <path
        d="M80.3904 143.162C80.317 143.169 80.243 143.169 80.1695 143.162C80.1566 143.161 80.1443 143.156 80.1341 143.148C80.1239 143.14 80.1163 143.129 80.1123 143.117C80.1065 143.105 80.1035 143.092 80.1035 143.079C80.1035 143.067 80.1065 143.054 80.1123 143.042C80.1409 143.018 80.7421 142.388 81.1961 142.388C81.2504 142.387 81.3044 142.397 81.3545 142.418C81.4045 142.44 81.4495 142.471 81.4865 142.512C81.5272 142.546 81.5551 142.593 81.5655 142.645C81.5759 142.698 81.5683 142.752 81.5438 142.8C81.4252 143.03 80.7994 143.162 80.3904 143.162ZM80.3372 143.018C80.7462 143.018 81.3393 142.89 81.4374 142.709C81.4374 142.709 81.4661 142.656 81.4006 142.59C81.3768 142.564 81.3476 142.543 81.3151 142.53C81.2826 142.516 81.2476 142.51 81.2125 142.512C80.8772 142.589 80.5725 142.765 80.3372 143.018Z"
        fill="#5384EE"
      />
      <path
        d="M108.285 153.336C107.975 153.334 107.666 153.305 107.361 153.25C107.346 153.247 107.332 153.239 107.322 153.227C107.313 153.215 107.307 153.199 107.308 153.184C107.305 153.17 107.307 153.155 107.313 153.142C107.319 153.129 107.328 153.118 107.34 153.11C107.385 153.085 108.432 152.48 108.935 152.62C108.982 152.631 109.026 152.651 109.065 152.679C109.103 152.707 109.136 152.743 109.16 152.785C109.192 152.831 109.21 152.885 109.21 152.941C109.21 152.997 109.192 153.052 109.16 153.098C109.042 153.283 108.67 153.336 108.285 153.336ZM107.594 153.143C108.15 153.225 108.911 153.221 109.05 153.023C109.07 152.999 109.087 152.953 109.05 152.867C109.019 152.816 108.969 152.779 108.911 152.764C108.592 152.682 107.958 152.953 107.594 153.143Z"
        fill="#5384EE"
      />
      <path
        d="M107.378 153.254C107.364 153.258 107.35 153.258 107.337 153.254C107.326 153.246 107.317 153.236 107.312 153.223C107.306 153.211 107.305 153.197 107.308 153.184C107.336 152.755 107.499 152.347 107.775 152.019C107.83 151.971 107.895 151.935 107.966 151.914C108.037 151.894 108.111 151.889 108.184 151.9C108.38 151.9 108.437 152.019 108.449 152.097C108.503 152.439 107.787 153.093 107.423 153.258L107.378 153.254ZM108.11 152.019C108.02 152.018 107.933 152.05 107.864 152.11C107.633 152.369 107.49 152.697 107.455 153.044C107.824 152.817 108.314 152.307 108.273 152.101C108.273 152.101 108.273 152.031 108.134 152.019H108.11Z"
        fill="#5384EE"
      />
      <path
        d="M104.072 153.18H107.078L107.377 146.179H104.371L104.072 153.18Z"
        fill="#E4897B"
      />
      <path
        d="M77.3027 141.162L79.814 142.816L85.3601 138.104L82.8447 136.445L77.3027 141.162Z"
        fill="#E4897B"
      />
      <path
        d="M80.2273 142.66L77.6178 140.507C77.5717 140.469 77.5139 140.448 77.4542 140.448C77.3946 140.448 77.3367 140.469 77.2906 140.507L75.1393 142.207C75.0873 142.249 75.0453 142.302 75.0165 142.362C74.9876 142.422 74.9727 142.488 74.9727 142.555C74.9727 142.622 74.9876 142.688 75.0165 142.748C75.0453 142.808 75.0873 142.861 75.1393 142.903C76.0636 143.64 76.5503 143.952 77.6996 144.899C78.4072 145.479 80.1537 147.257 81.1394 148.06C82.1251 148.862 83.0289 147.998 82.7017 147.582C81.2334 145.71 80.6567 144.109 80.489 143.117C80.4601 142.938 80.367 142.775 80.2273 142.66Z"
        fill="#263238"
      />
      <path
        d="M107.058 152.83H103.786C103.726 152.83 103.668 152.85 103.621 152.888C103.575 152.925 103.542 152.977 103.528 153.036L102.935 155.723C102.92 155.788 102.92 155.856 102.935 155.921C102.95 155.986 102.979 156.047 103.021 156.099C103.063 156.151 103.116 156.193 103.176 156.221C103.236 156.249 103.302 156.263 103.369 156.262C104.551 156.242 106.26 156.172 107.749 156.172C109.487 156.172 110.992 156.266 113.029 156.266C114.256 156.266 114.608 155.011 114.088 154.9C111.741 154.381 109.819 154.328 107.79 153.064C107.572 152.919 107.319 152.838 107.058 152.83Z"
        fill="#263238"
      />
      <path
        d="M89.1721 59.7564C86.5667 60.9912 85.082 69.2638 85.082 69.2638L91.4502 71.8197C92.3823 69.5293 93.1358 67.1693 93.7038 64.7612C94.5791 60.95 91.8388 58.4806 89.1721 59.7564Z"
        fill="#263238"
      />
      <path
        d="M92.5508 64.4194C90.9762 66.0246 90.2563 69.169 89.9414 71.2145L91.467 71.8278C92.3726 69.6024 93.1109 67.3117 93.6756 64.975C93.5406 64.1025 93.2052 63.7527 92.5508 64.4194Z"
        fill="#203048"
      />
      <path
        d="M106.547 59.6987C106.547 59.6987 109.746 63.337 106.727 85.8337H90.6575C90.5471 83.3642 92.0972 71.2433 89.7168 59.5464C91.4927 59.1999 93.2894 58.9716 95.0952 58.8632C97.37 58.7272 99.6507 58.7272 101.926 58.8632C103.485 59.0211 105.03 59.3005 106.547 59.6987Z"
        fill="#263238"
      />
      <path
        d="M108.015 68.4324L105.307 63.7363C104.785 65.9274 104.571 68.1817 104.673 70.4327C104.747 72.046 106.215 78.0221 107.536 78.8946C107.887 75.4187 108.047 71.926 108.015 68.4324Z"
        fill="#203048"
      />
      <path
        d="M108.392 62.9131C108.801 64.9133 109.21 66.963 109.648 68.9715C110.085 70.98 110.539 72.9967 111.067 74.9434C111.202 75.425 111.329 75.9189 111.476 76.3798L111.586 76.7338L111.64 76.9107V76.8902C111.611 76.8531 111.578 76.849 111.603 76.8902C111.771 77.0749 111.986 77.2101 112.224 77.2812C112.611 77.426 113.009 77.5375 113.415 77.6145C115.348 77.9229 117.306 78.0511 119.263 77.9973L119.652 80.2363C119.104 80.4132 118.593 80.5408 118.053 80.6684C117.513 80.796 116.981 80.903 116.437 80.9853C115.312 81.1739 114.171 81.2511 113.03 81.2158C112.396 81.1986 111.766 81.1159 111.149 80.9689C110.364 80.7962 109.627 80.4519 108.989 79.9605C108.573 79.6221 108.229 79.202 107.979 78.7258C107.914 78.6023 107.856 78.4624 107.803 78.343L107.73 78.1496L107.578 77.738C107.37 77.2153 107.198 76.7008 107.01 76.1864C106.318 74.1285 105.742 72.0501 105.235 69.9757C104.727 67.9014 104.29 65.8312 103.926 63.7033L108.392 62.9131Z"
        fill="#E4897B"
      />
      <path
        d="M102.535 61.9049C101.688 64.6666 105.12 72.9022 105.12 72.9022L111.954 71.1736C111.954 71.1736 111.692 66.8932 110.134 63.4977C107.786 58.3777 103.549 58.5917 102.535 61.9049Z"
        fill="#263238"
      />
      <path
        d="M119.205 77.9973L122.608 77.9109L120.972 81.4751C120.972 81.4751 119.585 81.6603 118.448 79.7794L117.25 78.3389L118.628 78.0632C118.818 78.023 119.011 78.0009 119.205 77.9973Z"
        fill="#E4897B"
      />
      <path
        d="M124.756 78.5735L122.809 81.5286L120.973 81.4751L122.609 77.9109L124.756 78.5735Z"
        fill="#E4897B"
      />
      <path
        d="M107.376 146.179L107.221 149.788H104.215L104.37 146.179H107.376Z"
        fill="#CE6F64"
      />
      <path
        d="M82.8453 136.445L85.3606 138.104L82.4976 140.532L79.9863 138.877L82.8453 136.445Z"
        fill="#CE6F64"
      />
      <path
        d="M95.9625 48.7591C96.1425 51.7512 96.0975 53.5169 97.5985 55.0521C99.8562 57.3692 103.533 55.9987 104.245 52.9942C104.887 50.3025 104.486 45.8575 101.574 44.6639C100.931 44.4029 100.234 44.3105 99.5458 44.3953C98.858 44.4801 98.2029 44.7393 97.642 45.1487C97.081 45.5581 96.6326 46.1041 96.3389 46.7356C96.0451 47.3671 95.9156 48.0634 95.9625 48.7591Z"
        fill="#E4897B"
      />
      <path
        d="M92.461 48.2324C93.2012 50.9611 94.9436 54.1055 97.1972 54.3072C99.9948 54.5541 101.259 51.6443 101.627 48.6398C103.304 47.4833 102.424 46.2115 104.105 46.051C106.265 45.8452 105.574 41.8694 102.731 41.2315C102.731 41.2315 103.684 43.0218 101.684 42.1163C99.7819 41.2067 97.6688 40.8381 95.5735 41.0504C95.5735 41.0504 98.1256 42.3345 95.6307 42.9066C93.1358 43.4787 92.3914 45.0262 93.5407 45.9605C93.5407 45.9646 91.9742 46.4379 92.461 48.2324Z"
        fill="#263238"
      />
      <path
        d="M99.9211 49.2571C99.976 50.0009 100.274 50.7055 100.768 51.2615C101.422 51.99 102.105 51.5372 102.22 50.6523C102.326 49.858 102.109 48.5204 101.308 48.117C100.506 47.7137 99.8679 48.3599 99.9211 49.2571Z"
        fill="#E4897B"
      />
      <path
        d="M101.024 85.8337C101.024 85.8337 103.953 107.174 101.646 115.726C97.9896 129.267 84.0099 140.697 84.0099 140.697L79.2695 137.569C79.2695 137.569 91.1756 127.917 92.6316 116.578C93.9405 106.4 90.6562 94.0899 90.6562 85.8296L101.024 85.8337Z"
        fill="#5384EE"
      />
      <path
        d="M101.024 85.8337C101.024 85.8337 103.953 107.174 101.646 115.726C97.9896 129.267 84.0099 140.697 84.0099 140.697L79.2695 137.569C79.2695 137.569 91.1756 127.917 92.6316 116.578C93.9405 106.4 90.6562 94.0899 90.6562 85.8296L101.024 85.8337Z"
        fill="url(#paint2_linear_314_424598)"
      />
      <path
        d="M102.236 98.0327C101.457 97.1339 100.598 96.3074 99.6719 95.5632C100.171 102.49 100.58 112.751 100.126 120.04C100.736 118.642 101.245 117.202 101.647 115.73C102.772 111.553 102.654 104.338 102.236 98.0327Z"
        fill="#5384EE"
      />
      <path
        opacity="0.3"
        d="M102.236 98.0327C101.457 97.1339 100.598 96.3074 99.6719 95.5632C100.171 102.49 100.58 112.751 100.126 120.04C100.736 118.642 101.245 117.202 101.647 115.73C102.772 111.553 102.654 104.338 102.236 98.0327Z"
        fill="black"
      />
      <path
        d="M85.9206 139.631C85.9411 139.631 84.0842 140.866 84.0842 140.866L78.9062 137.449L80.5423 136.087L85.9206 139.631Z"
        fill="#5384EE"
      />
      <path
        opacity="0.3"
        d="M85.9206 139.631C85.9411 139.631 84.0842 140.866 84.0842 140.866L78.9062 137.449L80.5423 136.087L85.9206 139.631Z"
        fill="black"
      />
      <path
        d="M106.727 85.8337C106.727 85.8337 111.25 105.137 111.569 114.36C111.929 124.699 108.342 149.089 108.342 149.089H103.25C103.25 149.089 102.624 124.509 102.399 115.093C102.15 104.824 96.1621 85.8337 96.1621 85.8337H106.727Z"
        fill="#5384EE"
      />
      <path
        d="M106.727 85.8337C106.727 85.8337 111.25 105.137 111.569 114.36C111.929 124.699 108.342 149.089 108.342 149.089H103.25C103.25 149.089 102.624 124.509 102.399 115.093C102.15 104.824 96.1621 85.8337 96.1621 85.8337H106.727Z"
        fill="url(#paint3_linear_314_424598)"
      />
      <path
        d="M109.32 147.051C109.345 147.051 109.034 149.175 109.034 149.175H102.842L102.674 147.278L109.32 147.051Z"
        fill="#5384EE"
      />
      <path
        opacity="0.3"
        d="M109.32 147.051C109.345 147.051 109.034 149.175 109.034 149.175H102.842L102.674 147.278L109.32 147.051Z"
        fill="black"
      />
      <path
        d="M185.018 67.2844C185.366 67.6137 185.64 67.8936 185.926 68.2064C186.212 68.5192 186.482 68.8237 186.744 69.1406C187.276 69.7703 187.775 70.433 188.257 71.0997C189.221 72.4372 190.079 73.8492 190.822 75.3225L190.973 75.6229C191.047 75.7684 191.109 75.9198 191.157 76.0757C191.256 76.3768 191.317 76.6894 191.337 77.0058C191.364 77.5639 191.288 78.122 191.112 78.6521C190.814 79.5326 190.37 80.3563 189.799 81.0886C188.772 82.4168 187.56 83.5895 186.2 84.5706L185.182 83.3935C185.66 82.8296 186.159 82.2246 186.605 81.6196C187.059 81.0334 187.469 80.4143 187.832 79.7675C188.171 79.2031 188.423 78.5895 188.576 77.9483C188.629 77.719 188.639 77.4816 188.605 77.2487C188.59 77.1703 188.566 77.0942 188.531 77.0223C188.516 76.9907 188.498 76.9604 188.478 76.9317L188.347 76.7095C187.559 75.4152 186.703 74.1637 185.783 72.96C185.329 72.355 184.842 71.7747 184.351 71.2026C184.11 70.9145 183.861 70.6346 183.611 70.3548C183.362 70.0749 183.092 69.795 182.875 69.5769L185.018 67.2844Z"
        fill="#E4897B"
      />
      <path
        d="M185.573 83.0475L182.518 82.5454L184.117 85.2371C184.117 85.2371 186.084 85.1671 186.644 84.0024L185.573 83.0475Z"
        fill="#E4897B"
      />
      <path
        d="M180.262 83.7884L181.718 85.9738L184.119 85.2371L182.519 82.5454L180.262 83.7884Z"
        fill="#E4897B"
      />
      <path
        d="M185.426 153.299C185.218 153.317 185.011 153.26 184.841 153.139C184.79 153.09 184.752 153.029 184.731 152.961C184.71 152.893 184.707 152.821 184.722 152.752C184.725 152.708 184.74 152.665 184.765 152.629C184.791 152.593 184.825 152.564 184.865 152.546C185.221 152.386 186.154 152.987 186.26 153.057C186.272 153.064 186.282 153.075 186.287 153.089C186.292 153.102 186.293 153.117 186.289 153.131C186.287 153.145 186.281 153.158 186.272 153.168C186.262 153.179 186.249 153.186 186.236 153.188C185.971 153.256 185.699 153.293 185.426 153.299ZM185.017 152.661C184.987 152.657 184.957 152.657 184.927 152.661C184.909 152.67 184.894 152.683 184.883 152.7C184.872 152.717 184.866 152.736 184.865 152.756C184.855 152.802 184.856 152.85 184.869 152.895C184.882 152.941 184.906 152.982 184.939 153.015C185.094 153.155 185.495 153.176 186.031 153.069C185.724 152.865 185.379 152.726 185.017 152.661Z"
        fill="#5384EE"
      />
      <path
        d="M186.244 153.184H186.207C185.917 153.016 185.356 152.361 185.426 152.003C185.439 151.947 185.47 151.896 185.514 151.859C185.559 151.822 185.614 151.8 185.671 151.797C185.726 151.789 185.783 151.794 185.836 151.811C185.889 151.828 185.938 151.857 185.978 151.896C186.313 152.192 186.317 153.081 186.317 153.131C186.317 153.143 186.313 153.155 186.307 153.166C186.3 153.177 186.291 153.186 186.281 153.192L186.244 153.184ZM185.72 151.95H185.688C185.598 151.95 185.577 151.999 185.569 152.036C185.528 152.246 185.876 152.748 186.166 152.983C186.173 152.637 186.075 152.298 185.884 152.011C185.859 151.988 185.83 151.971 185.799 151.96C185.767 151.95 185.733 151.946 185.7 151.95H185.72Z"
        fill="#5384EE"
      />
      <path
        d="M171.487 153.299C171.226 153.324 170.964 153.258 170.747 153.11C170.698 153.066 170.66 153.011 170.637 152.95C170.613 152.888 170.605 152.822 170.612 152.756C170.615 152.716 170.627 152.677 170.649 152.644C170.67 152.61 170.7 152.582 170.735 152.563C171.115 152.349 172.334 152.974 172.473 153.052C172.486 153.061 172.496 153.072 172.502 153.086C172.509 153.1 172.511 153.115 172.51 153.131C172.507 153.145 172.501 153.158 172.49 153.169C172.48 153.179 172.467 153.186 172.452 153.188C172.135 153.256 171.812 153.293 171.487 153.299ZM170.943 152.661C170.896 152.658 170.85 152.668 170.808 152.69C170.794 152.697 170.781 152.708 170.772 152.722C170.764 152.736 170.759 152.752 170.759 152.768C170.753 152.811 170.757 152.855 170.771 152.896C170.786 152.937 170.81 152.974 170.841 153.003C171.029 153.172 171.532 153.201 172.211 153.085C171.815 152.873 171.387 152.73 170.943 152.661Z"
        fill="#5384EE"
      />
      <path
        d="M172.442 153.184H172.409C172.045 153.02 171.321 152.361 171.378 152.003C171.378 151.921 171.452 151.818 171.652 151.797C171.725 151.789 171.8 151.797 171.87 151.819C171.94 151.84 172.005 151.877 172.061 151.925C172.446 152.246 172.519 153.077 172.519 153.114C172.521 153.127 172.519 153.14 172.514 153.151C172.509 153.163 172.501 153.173 172.491 153.18C172.475 153.187 172.458 153.188 172.442 153.184ZM171.709 151.95H171.665C171.534 151.95 171.525 152.015 171.521 152.032C171.489 152.242 171.979 152.765 172.339 152.991C172.304 152.639 172.161 152.307 171.93 152.04C171.868 151.987 171.791 151.955 171.709 151.95Z"
        fill="#5384EE"
      />
      <path
        d="M177.274 57.8223C177.156 59.6826 176.837 63.4608 178.256 64.5062C178.256 64.5062 177.998 66.4118 174.649 66.8563C170.968 67.3379 172.649 65.2471 172.649 65.2471C174.6 64.498 174.35 63.004 173.818 61.6376L177.274 57.8223Z"
        fill="#E4897B"
      />
      <path
        d="M175.811 153.118H172.744L172.539 145.978H175.607L175.811 153.118Z"
        fill="#E4897B"
      />
      <path
        d="M189.737 153.118H186.669L185.684 145.978H188.751L189.737 153.118Z"
        fill="#E4897B"
      />
      <path
        d="M186.481 152.772H189.925C189.984 152.772 190.041 152.793 190.085 152.832C190.13 152.87 190.158 152.924 190.166 152.982L190.575 155.728C190.583 155.797 190.576 155.867 190.554 155.933C190.533 155.999 190.498 156.06 190.452 156.112C190.406 156.164 190.349 156.205 190.286 156.233C190.222 156.261 190.154 156.275 190.085 156.275C188.886 156.254 188.305 156.184 186.792 156.184C185.86 156.184 184.498 156.279 183.213 156.279C181.929 156.279 181.86 154.999 182.395 154.884C184.796 154.365 185.177 153.649 185.982 152.962C186.123 152.844 186.299 152.778 186.481 152.772Z"
        fill="#263238"
      />
      <path
        d="M172.642 152.772H176.081C176.14 152.772 176.198 152.793 176.243 152.831C176.288 152.87 176.318 152.923 176.327 152.982L176.736 155.728C176.743 155.797 176.736 155.867 176.715 155.933C176.694 155.999 176.659 156.06 176.612 156.112C176.566 156.164 176.509 156.205 176.446 156.233C176.383 156.261 176.314 156.275 176.245 156.275C175.047 156.254 174.466 156.184 172.952 156.184C172.02 156.184 170.089 156.279 168.805 156.279C167.521 156.279 167.451 154.999 167.987 154.884C170.388 154.365 171.333 153.649 172.143 152.962C172.283 152.844 172.459 152.778 172.642 152.772Z"
        fill="#263238"
      />
      <path
        d="M172.539 145.978L172.645 149.661H175.713L175.607 145.978H172.539Z"
        fill="#CE6F64"
      />
      <path
        d="M188.751 145.978H185.684L186.195 149.661H189.258L188.751 145.978Z"
        fill="#CE6F64"
      />
      <path
        d="M177.316 53.9699C177.525 57.0238 177.676 58.312 176.359 60.0694C174.375 62.7076 170.604 62.3001 169.492 59.3697C168.49 56.7315 168.318 52.1836 171.107 50.5867C171.721 50.2328 172.414 50.0426 173.122 50.034C173.829 50.0254 174.526 50.1988 175.148 50.5377C175.77 50.8766 176.296 51.3698 176.675 51.9704C177.055 52.5711 177.275 53.2593 177.316 53.9699Z"
        fill="#E4897B"
      />
      <path
        d="M179.083 56.8056C180.784 57.7605 182.048 55.4063 182.048 55.4063C182.048 55.4063 178.997 55.7397 179.271 53.9123C179.631 51.4881 177.905 48.5412 173.578 48.9199C173.336 48.9404 173.107 48.9693 172.882 49.0063C172.429 48.7902 171.917 48.7344 171.429 48.848C170.94 48.9615 170.504 49.2378 170.191 49.6319C168.964 49.7348 167.308 51.2082 168.432 53.155C168.511 52.9313 168.634 52.7256 168.792 52.55C168.886 53.69 169.876 55.5009 171.111 55.6203C171.377 57.1802 170.347 58.987 170.702 60.6168C171.23 62.9669 175.169 63.4978 175.169 63.4978C175.033 63.2044 174.982 62.8782 175.023 62.557C175.063 62.2359 175.193 61.9328 175.398 61.6828C178.073 62.9175 179.835 61.7116 179.835 61.7116C178.011 61.0284 178.563 59.8101 178.563 59.8101C179.193 60.0916 179.907 60.1163 180.555 59.8791C181.202 59.6419 181.733 59.1613 182.036 58.5384C181.43 58.5673 180.829 58.4191 180.305 58.1116C179.781 57.8041 179.356 57.3505 179.083 56.8056Z"
        fill="#263238"
      />
      <path
        d="M172.179 56.0894C172.186 56.3825 172.135 56.6741 172.03 56.9475C171.925 57.2209 171.767 57.4706 171.565 57.6822C171.025 58.2461 170.363 57.851 170.183 57.1307C170.023 56.4804 170.113 55.4062 170.817 55.1099C170.971 55.0486 171.139 55.0273 171.304 55.0479C171.469 55.0684 171.627 55.1302 171.762 55.2275C171.897 55.3248 172.006 55.4547 172.079 55.6052C172.152 55.7557 172.186 55.9222 172.179 56.0894Z"
        fill="#E4897B"
      />
      <path
        d="M174.727 84.5417C174.727 84.5417 175.504 104.857 177.352 116.785C178.841 126.424 185.455 148.945 185.455 148.945H189.635C189.635 148.945 186.187 127.193 185.43 117.665C183.516 93.5511 188.485 88.3735 183.794 83.3523L174.727 84.5417Z"
        fill="#263238"
      />
      <path
        d="M178.22 90.9663C178.22 90.9663 176.331 90.637 175.227 94.2589C175.636 101.12 176.335 110.224 177.353 116.784C177.578 118.245 177.922 120.007 178.343 121.937C177.546 110.347 178.22 90.9663 178.22 90.9663Z"
        fill="#161F33"
      />
      <path
        d="M171.467 84.9698C171.467 84.9698 168.023 107.446 168.223 116.85C168.432 126.634 171.994 148.953 171.994 148.953H176.137C176.137 148.953 175.483 127.214 175.847 117.608C176.256 107.133 180.506 83.7886 180.506 83.7886L171.467 84.9698Z"
        fill="#263238"
      />
      <path
        d="M190.24 149.143H184.682L184.248 147.266L190.199 147.307L190.24 149.143Z"
        fill="#5384EE"
      />
      <path
        opacity="0.2"
        d="M190.24 149.143H184.682L184.248 147.266L190.199 147.307L190.24 149.143Z"
        fill="black"
      />
      <path
        d="M183.585 64.5105C185.185 64.6669 187.614 69.2395 187.614 69.2395L183.577 72.3551C183.577 72.3551 180.236 69.4041 180.632 67.7455C181.045 66.021 182.052 64.3664 183.585 64.5105Z"
        fill="#5384EE"
      />
      <path
        d="M183.585 64.5105C185.185 64.6669 187.614 69.2395 187.614 69.2395L183.577 72.3551C183.577 72.3551 180.236 69.4041 180.632 67.7455C181.045 66.021 182.052 64.3664 183.585 64.5105Z"
        fill="url(#paint4_linear_314_424598)"
      />
      <path
        d="M182.379 66.4778C181.971 66.3673 181.549 66.3201 181.127 66.3379C180.912 66.7879 180.747 67.2598 180.632 67.7455C180.223 69.3918 183.577 72.3551 183.577 72.3551L183.876 72.1246L182.379 66.4778Z"
        fill="#5384EE"
      />
      <path
        opacity="0.3"
        d="M182.379 66.4778C181.971 66.3673 181.549 66.3201 181.127 66.3379C180.912 66.7879 180.747 67.2598 180.632 67.7455C180.223 69.3918 183.577 72.3551 183.577 72.3551L183.876 72.1246L182.379 66.4778Z"
        fill="black"
      />
      <path
        d="M170.097 70.8692C168.931 73.8367 167.704 76.8535 166.727 79.8292C166.661 80.0186 166.608 80.1996 166.551 80.3849L166.412 80.8417C166.374 80.9762 166.374 81.1188 166.412 81.2533C166.643 81.7678 167.009 82.2091 167.471 82.5292C168.694 83.4121 170.026 84.1302 171.434 84.6652L171.066 86.1798C169.334 85.95 167.659 85.4003 166.125 84.5582C165.182 84.0509 164.413 83.2673 163.921 82.311C163.638 81.6998 163.54 81.0187 163.639 80.3519C163.648 80.2703 163.662 80.1893 163.68 80.1091L163.721 79.9362L163.79 79.6234C163.839 79.4135 163.888 79.2119 163.941 79.0061C164.35 77.3598 164.874 75.7958 165.426 74.2442C165.978 72.6925 166.555 71.1615 167.205 69.6345L170.097 70.8692Z"
        fill="#E4897B"
      />
      <path
        d="M167.614 66.7042C167.614 66.7042 166.232 67.4079 171.467 84.9698L183.811 83.3482C182.944 78.3188 182.535 75.2032 183.586 64.5105C181.816 64.3829 180.039 64.3829 178.269 64.5105C176.384 64.6207 174.51 64.8683 172.661 65.2513C170.293 65.7822 167.614 66.7042 167.614 66.7042Z"
        fill="#5384EE"
      />
      <path
        d="M167.614 66.7042C167.614 66.7042 166.232 67.4079 171.467 84.9698L183.811 83.3482C182.944 78.3188 182.535 75.2032 183.586 64.5105C181.816 64.3829 180.039 64.3829 178.269 64.5105C176.384 64.6207 174.51 64.8683 172.661 65.2513C170.293 65.7822 167.614 66.7042 167.614 66.7042Z"
        fill="url(#paint5_linear_314_424598)"
      />
      <path
        d="M183.901 82.595L184.608 83.628C184.661 83.7104 184.571 83.8091 184.432 83.8297L171.487 85.5336C171.377 85.5336 171.275 85.5048 171.258 85.4349L171.005 84.336C170.984 84.2619 171.074 84.1837 171.197 84.1672L183.696 82.5209C183.734 82.5149 183.772 82.5185 183.808 82.5314C183.844 82.5444 183.876 82.5663 183.901 82.595Z"
        fill="#5384EE"
      />
      <path
        opacity="0.2"
        d="M183.901 82.595L184.608 83.628C184.661 83.7104 184.571 83.8091 184.432 83.8297L171.487 85.5336C171.377 85.5336 171.275 85.5048 171.258 85.4349L171.005 84.336C170.984 84.2619 171.074 84.1837 171.197 84.1672L183.696 82.5209C183.734 82.5149 183.772 82.5185 183.808 82.5314C183.844 82.5444 183.876 82.5663 183.901 82.595Z"
        fill="black"
      />
      <path
        d="M182.405 84.2206L182.74 84.1753C182.805 84.1753 182.85 84.1259 182.842 84.0848L182.499 82.6607C182.486 82.6364 182.465 82.6174 182.439 82.6069C182.414 82.5964 182.386 82.595 182.36 82.6031L182.024 82.6443C181.955 82.6443 181.91 82.6936 181.922 82.7389L182.265 84.1588C182.274 84.2041 182.339 84.2288 182.405 84.2206Z"
        fill="#263238"
      />
      <path
        d="M174.289 85.2743L174.625 85.229C174.69 85.229 174.739 85.1796 174.727 85.1385L174.383 83.7144C174.37 83.6904 174.349 83.6714 174.324 83.6603C174.299 83.6491 174.271 83.6465 174.244 83.6527L173.909 83.698C173.843 83.698 173.794 83.7474 173.807 83.7885L174.15 85.2126C174.158 85.2743 174.224 85.299 174.289 85.2743Z"
        fill="#263238"
      />
      <path
        d="M168.191 72.6637C168.531 74.2523 169.009 76.2649 169.709 78.8126L169.86 72.355L168.191 72.6637Z"
        fill="#5384EE"
      />
      <path
        opacity="0.3"
        d="M168.191 72.6637C168.531 74.2523 169.009 76.2649 169.709 78.8126L169.86 72.355L168.191 72.6637Z"
        fill="black"
      />
      <path
        d="M167.615 66.704C166.081 67.3254 164.752 72.6059 164.752 72.6059L170.028 75.1906C170.028 75.1906 172.576 68.733 171.435 67.4736C170.294 66.2142 169.382 65.9919 167.615 66.704Z"
        fill="#5384EE"
      />
      <path
        d="M167.615 66.704C166.081 67.3254 164.752 72.6059 164.752 72.6059L170.028 75.1906C170.028 75.1906 172.576 68.733 171.435 67.4736C170.294 66.2142 169.382 65.9919 167.615 66.704Z"
        fill="url(#paint6_linear_314_424598)"
      />
      <path
        d="M176.845 149.143H171.287L170.854 147.266L177.042 147.307L176.845 149.143Z"
        fill="#5384EE"
      />
      <path
        opacity="0.2"
        d="M176.845 149.143H171.287L170.854 147.266L177.042 147.307L176.845 149.143Z"
        fill="black"
      />
      <path
        d="M171.52 84.1546L171.074 83.9694C170.964 83.9246 170.845 83.9097 170.728 83.9263C170.61 83.9429 170.5 83.9904 170.407 84.0639C170.314 84.1375 170.242 84.2345 170.198 84.3451C170.154 84.4557 170.14 84.576 170.158 84.6938L170.481 86.8381C170.496 86.9454 170.535 87.0479 170.595 87.1382C170.655 87.2285 170.734 87.3044 170.826 87.3603C170.918 87.4162 171.022 87.4508 171.129 87.4616C171.236 87.4723 171.345 87.459 171.446 87.4225C171.608 87.3632 171.76 87.2785 171.896 87.1715C172.044 87.048 172.161 86.8913 172.238 86.714C172.314 86.5368 172.349 86.3438 172.338 86.1507L172.293 85.2617C172.285 85.0215 172.208 84.7888 172.07 84.5921C171.933 84.3955 171.742 84.2434 171.52 84.1546Z"
        fill="#E4897B"
      />
      <defs>
        <linearGradient
          id="paint0_linear_314_424598"
          x1="140"
          y1="0"
          x2="140"
          y2="145"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#363636" />
          <stop offset="1" stopColor="#242424" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_314_424598"
          x1="77.9966"
          y1="34.2576"
          x2="91.6497"
          y2="110.923"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4F8AED" />
          <stop offset="0.4327" stopColor="#5384EE" />
          <stop offset="0.8061" stopColor="#5159C0" />
          <stop offset="1" stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_314_424598"
          x1="78.1821"
          y1="94.1959"
          x2="104.45"
          y2="137.099"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4F8AED" />
          <stop offset="0.4327" stopColor="#5384EE" />
          <stop offset="0.8061" stopColor="#5159C0" />
          <stop offset="1" stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_314_424598"
          x1="95.4407"
          y1="95.4791"
          x2="129.377"
          y2="127.375"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4F8AED" />
          <stop offset="0.4327" stopColor="#5384EE" />
          <stop offset="0.8061" stopColor="#5159C0" />
          <stop offset="1" stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_314_424598"
          x1="180.272"
          y1="65.6992"
          x2="182.534"
          y2="73.4846"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4F8AED" />
          <stop offset="0.4327" stopColor="#5384EE" />
          <stop offset="0.8061" stopColor="#5159C0" />
          <stop offset="1" stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_314_424598"
          x1="166.684"
          y1="67.5491"
          x2="173.198"
          y2="87.523"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4F8AED" />
          <stop offset="0.4327" stopColor="#5384EE" />
          <stop offset="0.8061" stopColor="#5159C0" />
          <stop offset="1" stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_314_424598"
          x1="164.426"
          y1="67.6578"
          x2="167.274"
          y2="76.2713"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4F8AED" />
          <stop offset="0.4327" stopColor="#5384EE" />
          <stop offset="0.8061" stopColor="#5159C0" />
          <stop offset="1" stopColor="#626DF0" />
        </linearGradient>
      </defs>
    </svg>
  ) : (
    <svg
      width="280"
      height="160"
      viewBox="0 0 280 160"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M45.3677 144.262C45.3677 144.262 -1.06674 124.455 0.0187389 84.3494C1.10422 44.2436 44.6441 37.9694 74.4345 62.6972C104.346 87.425 119.659 75.3275 135.097 54.4134C150.535 33.4993 174.299 -13.8237 217.598 3.89173C249.921 17.0553 271.51 52.3632 278.505 84.2264C283.329 106.125 276.334 129.253 259.569 143.647C258.966 144.139 258.484 144.631 257.881 145L45.3677 144.262Z"
        fill="url(#paint0_linear_314_423643)"
      />
      <path
        d="M142.227 159.28C186.801 159.28 222.935 157.184 222.935 154.598C222.935 152.011 186.801 149.915 142.227 149.915C97.6537 149.915 61.5195 152.011 61.5195 154.598C61.5195 157.184 97.6537 159.28 142.227 159.28Z"
        fill="#F5F5F5"
      />
      <path
        d="M92.4258 62.7708C92.0595 63.5982 91.6557 64.4917 91.3019 65.3645C90.9481 66.2374 90.5818 67.135 90.2613 68.0286C89.6036 69.7705 89.0763 71.5582 88.6837 73.3773L88.5629 74.0392L88.5213 74.2874C88.5213 74.3577 88.5213 74.4487 88.4963 74.5315C88.4805 74.9342 88.4889 75.3376 88.5213 75.7394C88.6004 76.6122 88.7419 77.5471 88.9376 78.482C89.2872 80.356 89.7326 82.2878 90.1863 84.1907L88.051 85.018C87.0555 83.2147 86.2078 81.3347 85.516 79.3962C85.1515 78.3873 84.8566 77.3549 84.6335 76.3061C84.5085 75.7258 84.4305 75.1365 84.4004 74.5439C84.4004 74.3784 84.4004 74.2212 84.4004 74.0433C84.4004 73.8655 84.4004 73.6669 84.4295 73.5511L84.5128 72.7237C84.6372 71.6582 84.8276 70.6013 85.0831 69.5592C85.3162 68.525 85.6159 67.5198 85.9156 66.5269C86.2153 65.5341 86.5899 64.5579 86.9853 63.5982C87.181 63.1183 87.4016 62.6426 87.5931 62.1669C87.7846 61.6911 88.0093 61.2361 88.2757 60.7273L92.4258 62.7708Z"
        fill="#E4897B"
      />
      <path
        d="M90.0751 83.6321L91.6985 85.2082L88.1561 86.8628C88.1561 86.8628 87.2986 85.3695 87.8939 84.0209L90.0751 83.6321Z"
        fill="#E4897B"
      />
      <path
        d="M93.0095 87.5535L90.0167 88.7697L88.1602 86.8461L91.6983 85.208L93.0095 87.5535Z"
        fill="#E4897B"
      />
      <path
        d="M123.241 44.8752L122.846 44.7428C123.531 42.7344 124.391 40.7896 125.418 38.9307L125.78 39.1293C124.767 40.9674 123.917 42.89 123.241 44.8752ZM128.294 35.2201L127.957 34.976C131.378 30.3793 135.855 26.6618 141.015 24.1337L141.203 24.506C136.101 27.008 131.675 30.6857 128.294 35.2325V35.2201ZM173.912 25.0728C172.552 24.3477 171.144 23.7134 169.699 23.174L169.849 22.7852C171.308 23.3372 172.73 23.9839 174.103 24.7211L173.912 25.0728Z"
        fill="#5384EE"
      />
      <path
        opacity="0.3"
        d="M123.241 44.8752L122.846 44.7428C123.531 42.7344 124.391 40.7896 125.418 38.9307L125.78 39.1293C124.767 40.9674 123.917 42.89 123.241 44.8752ZM128.294 35.2201L127.957 34.976C131.378 30.3793 135.855 26.6618 141.015 24.1337L141.203 24.506C136.101 27.008 131.675 30.6857 128.294 35.2325V35.2201ZM173.912 25.0728C172.552 24.3477 171.144 23.7134 169.699 23.174L169.849 22.7852C171.308 23.3372 172.73 23.9839 174.103 24.7211L173.912 25.0728Z"
        fill="black"
      />
      <path
        d="M187.961 41.0446C184.107 33.1002 177.324 26.9345 169.019 23.8256C160.714 20.7168 151.523 20.9032 143.353 24.3463C135.182 27.7895 128.658 34.2252 125.133 42.3193C121.608 50.4134 121.353 59.5452 124.42 67.8215C121.639 68.7895 118.892 69.8278 116.161 70.8992C112.223 72.4518 108.322 74.0734 104.456 75.7639C100.593 77.4641 96.7469 79.1974 92.9381 81.0093C91.0275 81.8987 89.1419 82.8377 87.2396 83.7478C86.25 84.1704 85.3534 84.7817 84.6005 85.5473C84.1931 85.9712 83.8344 86.4387 83.5308 86.9413C83.1603 87.5358 82.8798 88.1811 82.6983 88.8566C82.385 90.0739 82.5205 91.3625 83.0801 92.4892C83.6397 93.6159 84.5866 94.5064 85.7494 94.9996C86.3951 95.2688 87.0764 95.4443 87.7724 95.5208C88.3602 95.587 88.9537 95.587 89.5415 95.5208C90.6097 95.392 91.6421 95.0561 92.5802 94.5322C94.4658 93.5849 96.3639 92.6707 98.2371 91.6985C101.983 89.7998 105.73 87.8183 109.43 85.8079C113.131 83.7974 116.835 81.6918 120.449 79.5325C122.946 78.0309 125.469 76.4961 127.941 74.8911C132.689 82.3399 140.139 87.6879 148.746 89.8269C157.354 91.966 166.46 90.732 174.177 86.3808C181.894 82.0296 187.63 74.8947 190.198 66.4545C192.766 58.0143 191.968 48.9159 187.969 41.0446H187.961ZM169.038 80.5625C164.201 82.9355 158.764 83.8296 153.415 83.1319C148.066 82.4343 143.046 80.1761 138.988 76.643C134.931 73.1098 132.019 68.4604 130.62 63.2825C129.222 58.1047 129.399 52.631 131.131 47.5535C132.862 42.4761 136.07 38.0229 140.348 34.757C144.626 31.4912 149.783 29.5594 155.166 29.2058C160.549 28.8523 165.916 30.0929 170.59 32.7708C175.263 35.4487 179.033 39.4437 181.422 44.2505C183.008 47.4428 183.946 50.9144 184.183 54.467C184.419 58.0197 183.949 61.5838 182.799 64.9558C181.649 68.3277 179.842 71.4416 177.48 74.1194C175.119 76.7973 172.251 78.9866 169.038 80.5625Z"
        fill="#5384EE"
      />
      <path
        d="M187.961 41.0446C184.107 33.1002 177.324 26.9345 169.019 23.8256C160.714 20.7168 151.523 20.9032 143.353 24.3463C135.182 27.7895 128.658 34.2252 125.133 42.3193C121.608 50.4134 121.353 59.5452 124.42 67.8215C121.639 68.7895 118.892 69.8278 116.161 70.8992C112.223 72.4518 108.322 74.0734 104.456 75.7639C100.593 77.4641 96.7469 79.1974 92.9381 81.0093C91.0275 81.8987 89.1419 82.8377 87.2396 83.7478C86.25 84.1704 85.3534 84.7817 84.6005 85.5473C84.1931 85.9712 83.8344 86.4387 83.5308 86.9413C83.1603 87.5358 82.8798 88.1811 82.6983 88.8566C82.385 90.0739 82.5205 91.3625 83.0801 92.4892C83.6397 93.6159 84.5866 94.5064 85.7494 94.9996C86.3951 95.2688 87.0764 95.4443 87.7724 95.5208C88.3602 95.587 88.9537 95.587 89.5415 95.5208C90.6097 95.392 91.6421 95.0561 92.5802 94.5322C94.4658 93.5849 96.3639 92.6707 98.2371 91.6985C101.983 89.7998 105.73 87.8183 109.43 85.8079C113.131 83.7974 116.835 81.6918 120.449 79.5325C122.946 78.0309 125.469 76.4961 127.941 74.8911C132.689 82.3399 140.139 87.6879 148.746 89.8269C157.354 91.9659 166.46 90.732 174.177 86.3808C181.894 82.0296 187.63 74.8947 190.198 66.4545C192.766 58.0143 191.968 48.9159 187.969 41.0446H187.961ZM169.038 80.5625C164.201 82.9355 158.764 83.8296 153.415 83.1319C148.066 82.4343 143.046 80.1761 138.988 76.643C134.931 73.1098 132.019 68.4604 130.62 63.2825C129.222 58.1047 129.399 52.631 131.131 47.5535C132.862 42.4761 136.07 38.0229 140.348 34.757C144.626 31.4912 149.783 29.5594 155.166 29.2058C160.549 28.8523 165.916 30.0929 170.59 32.7708C175.263 35.4487 179.033 39.4437 181.422 44.2505C183.008 47.4428 183.946 50.9144 184.183 54.467C184.419 58.0197 183.949 61.5838 182.799 64.9558C181.649 68.3277 179.842 71.4416 177.48 74.1194C175.119 76.7973 172.251 78.9866 169.038 80.5625Z"
        fill="url(#paint1_linear_314_423643)"
      />
      <path
        d="M95.523 49.8394C95.9393 52.0608 96.3972 56.1354 94.832 57.6205C94.832 57.6205 95.4439 59.875 99.6023 59.875C104.181 59.875 101.784 57.6205 101.784 57.6205C99.286 57.0248 99.3567 55.1881 99.7896 53.459L95.523 49.8394Z"
        fill="#E4897B"
      />
      <path
        d="M92.9641 58.7911C93.4595 58.0258 92.627 56.1684 92.627 56.1684C92.627 56.1684 99.9864 54.3276 102.996 57.1529C103.62 57.7362 102.929 58.5966 102.929 58.5966L92.9641 58.7911Z"
        fill="#5384EE"
      />
      <path
        opacity="0.1"
        d="M92.9641 58.7911C93.4595 58.0258 92.627 56.1684 92.627 56.1684C92.627 56.1684 99.9864 54.3276 102.996 57.1529C103.62 57.7362 102.929 58.5966 102.929 58.5966L92.9641 58.7911Z"
        fill="black"
      />
      <path
        d="M80.7885 143.192C80.4014 143.192 79.7812 142.551 79.598 142.311C79.5901 142.3 79.5859 142.286 79.5859 142.272C79.5859 142.258 79.5901 142.244 79.598 142.233C79.6047 142.221 79.6145 142.211 79.6262 142.205C79.6379 142.198 79.6512 142.195 79.6646 142.195C79.7104 142.195 80.7302 142.286 81.0091 142.646C81.0394 142.684 81.0611 142.728 81.0726 142.775C81.0841 142.823 81.0852 142.872 81.0757 142.919C81.0721 142.981 81.0493 143.04 81.0106 143.088C80.9718 143.136 80.9189 143.171 80.8593 143.188L80.7885 143.192ZM79.8228 142.365C80.1267 142.712 80.6137 143.105 80.8218 143.056C80.8218 143.056 80.9051 143.035 80.93 142.903C80.9355 142.875 80.9346 142.847 80.9274 142.819C80.9202 142.792 80.9069 142.767 80.8884 142.746C80.58 142.513 80.209 142.378 79.8228 142.357V142.365Z"
        fill="#5384EE"
      />
      <path
        d="M79.8681 142.357C79.7934 142.364 79.7181 142.364 79.6434 142.357C79.6302 142.356 79.6176 142.351 79.6073 142.343C79.5969 142.335 79.5892 142.324 79.5851 142.311C79.5792 142.3 79.5762 142.287 79.5762 142.274C79.5762 142.261 79.5792 142.248 79.5851 142.237C79.6142 142.212 80.2261 141.579 80.6882 141.579C80.7434 141.578 80.7984 141.588 80.8493 141.61C80.9003 141.631 80.9461 141.663 80.9837 141.703C81.0251 141.738 81.0535 141.785 81.0641 141.838C81.0747 141.89 81.0669 141.945 81.042 141.993C80.9213 142.224 80.2844 142.357 79.8681 142.357ZM79.814 142.212C80.2303 142.212 80.8338 142.084 80.9337 141.902C80.9337 141.902 80.9629 141.848 80.8963 141.782C80.8721 141.755 80.8424 141.735 80.8093 141.721C80.7762 141.708 80.7406 141.702 80.7048 141.703C80.3636 141.781 80.0534 141.958 79.814 142.212Z"
        fill="#5384EE"
      />
      <path
        d="M108.258 152.583C107.942 152.581 107.627 152.551 107.317 152.496C107.301 152.493 107.287 152.485 107.278 152.473C107.268 152.46 107.262 152.445 107.263 152.43C107.26 152.415 107.262 152.401 107.268 152.387C107.274 152.374 107.284 152.363 107.296 152.355C107.342 152.33 108.407 151.722 108.919 151.863C108.967 151.874 109.012 151.894 109.051 151.922C109.09 151.951 109.123 151.987 109.148 152.028C109.181 152.074 109.198 152.129 109.198 152.186C109.198 152.242 109.181 152.297 109.148 152.343C109.028 152.529 108.649 152.583 108.258 152.583ZM107.554 152.388C108.12 152.471 108.894 152.467 109.036 152.268C109.057 152.243 109.073 152.198 109.036 152.111C109.004 152.06 108.953 152.022 108.894 152.008C108.57 151.925 107.925 152.198 107.554 152.388Z"
        fill="#5384EE"
      />
      <path
        d="M107.332 152.5C107.319 152.504 107.304 152.504 107.291 152.5C107.279 152.493 107.27 152.482 107.265 152.469C107.26 152.457 107.258 152.443 107.261 152.43C107.29 151.999 107.456 151.589 107.736 151.259C107.793 151.21 107.859 151.174 107.931 151.154C108.003 151.133 108.078 151.128 108.152 151.139C108.352 151.139 108.41 151.259 108.423 151.337C108.477 151.681 107.748 152.339 107.378 152.504L107.332 152.5ZM108.077 151.259C107.986 151.258 107.897 151.291 107.827 151.35C107.592 151.611 107.446 151.94 107.411 152.289C107.786 152.061 108.285 151.548 108.244 151.342C108.244 151.342 108.244 151.271 108.102 151.259H108.077Z"
        fill="#5384EE"
      />
      <path
        d="M103.969 152.425H107.028L107.332 145.389H104.273L103.969 152.425Z"
        fill="#E4897B"
      />
      <path
        d="M76.7246 140.346L79.2804 142.009L84.9248 137.273L82.3649 135.606L76.7246 140.346Z"
        fill="#E4897B"
      />
      <path
        d="M79.7013 141.852L77.0456 139.689C76.9987 139.65 76.9398 139.629 76.8791 139.629C76.8184 139.629 76.7595 139.65 76.7126 139.689L74.5231 141.397C74.4702 141.439 74.4275 141.492 74.3981 141.553C74.3688 141.613 74.3535 141.679 74.3535 141.747C74.3535 141.814 74.3688 141.88 74.3981 141.941C74.4275 142.001 74.4702 142.054 74.5231 142.096C75.4638 142.837 75.9592 143.151 77.1289 144.103C77.849 144.686 79.6264 146.473 80.6296 147.28C81.6328 148.086 82.5527 147.217 82.2197 146.8C80.7253 144.917 80.1384 143.308 79.9677 142.311C79.9382 142.131 79.8435 141.968 79.7013 141.852Z"
        fill="#263238"
      />
      <path
        d="M107.008 152.074H103.678C103.617 152.074 103.558 152.094 103.511 152.132C103.463 152.17 103.43 152.222 103.416 152.281L102.812 154.982C102.797 155.047 102.797 155.115 102.812 155.181C102.827 155.246 102.857 155.307 102.9 155.36C102.942 155.412 102.996 155.454 103.058 155.482C103.119 155.511 103.186 155.525 103.253 155.524C104.456 155.503 106.196 155.433 107.711 155.433C109.481 155.433 111.012 155.528 113.085 155.528C114.334 155.528 114.692 154.266 114.163 154.154C111.774 153.633 109.818 153.579 107.753 152.31C107.532 152.164 107.274 152.082 107.008 152.074Z"
        fill="#263238"
      />
      <path
        d="M88.8051 58.5265C86.1536 59.7675 84.6426 68.0823 84.6426 68.0823L91.1237 70.6511C92.0724 68.3491 92.8392 65.9771 93.4173 63.5567C94.308 59.7261 91.5191 57.2441 88.8051 58.5265Z"
        fill="#263238"
      />
      <path
        d="M92.2436 63.2132C90.641 64.8265 89.9084 67.9869 89.5879 70.0429L91.1405 70.6593C92.0622 68.4225 92.8136 66.1202 93.3883 63.7716C93.2509 62.8947 92.9096 62.543 92.2436 63.2132Z"
        fill="#203048"
      />
      <path
        d="M106.488 58.4683C106.488 58.4683 109.743 62.1252 106.671 84.7364H90.3168C90.2044 82.2544 91.782 70.0718 89.3594 58.3153C91.1668 57.967 92.9953 57.7376 94.8331 57.6286C97.1483 57.492 99.4695 57.492 101.785 57.6286C103.372 57.7873 104.945 58.0681 106.488 58.4683Z"
        fill="#263238"
      />
      <path
        d="M107.983 67.2466L105.227 62.5266C104.695 64.7289 104.478 66.9946 104.582 69.257C104.657 70.8786 106.151 76.8851 107.496 77.7621C107.852 74.2685 108.015 70.758 107.983 67.2466Z"
        fill="#203048"
      />
      <path
        d="M108.364 61.699C108.78 63.7094 109.196 65.7695 109.642 67.7882C110.087 69.8069 110.549 71.8339 111.086 73.7906C111.224 74.2746 111.353 74.771 111.502 75.2343L111.615 75.59L111.669 75.7679V75.7472C111.64 75.71 111.607 75.7059 111.631 75.7472C111.803 75.9329 112.021 76.0688 112.264 76.1402C112.658 76.2857 113.063 76.3978 113.475 76.4753C115.444 76.7853 117.436 76.914 119.428 76.86L119.823 79.1104C119.266 79.2882 118.745 79.4165 118.196 79.5447C117.646 79.673 117.105 79.7805 116.552 79.8632C115.406 80.0528 114.245 80.1303 113.084 80.0949C112.439 80.0776 111.797 79.9945 111.169 79.8467C110.371 79.6731 109.621 79.3271 108.972 78.8332C108.548 78.4931 108.198 78.0709 107.943 77.5922C107.877 77.4681 107.819 77.3274 107.764 77.2075L107.69 77.0131L107.536 76.5994C107.323 76.074 107.148 75.5569 106.957 75.0398C106.253 72.9715 105.667 70.8825 105.15 68.7976C104.634 66.7127 104.189 64.6319 103.818 62.4932L108.364 61.699Z"
        fill="#E4897B"
      />
      <path
        d="M102.404 60.6859C101.543 63.4616 105.035 71.7391 105.035 71.7391L111.991 70.0017C111.991 70.0017 111.724 65.6995 110.138 62.2868C107.749 57.1407 103.437 57.3558 102.404 60.6859Z"
        fill="#263238"
      />
      <path
        d="M119.371 76.8601L122.834 76.7732L121.169 80.3556C121.169 80.3556 119.758 80.5417 118.6 78.6513L117.381 77.2034L118.784 76.9262C118.977 76.8859 119.173 76.8637 119.371 76.8601Z"
        fill="#E4897B"
      />
      <path
        d="M125.02 77.4392L123.039 80.4094L121.17 80.3556L122.835 76.7732L125.02 77.4392Z"
        fill="#E4897B"
      />
      <path
        d="M107.333 145.389L107.175 149.017H104.115L104.273 145.389H107.333Z"
        fill="#CE6F64"
      />
      <path
        d="M82.3647 135.606L84.9247 137.273L82.0109 139.713L79.4551 138.051L82.3647 135.606Z"
        fill="#CE6F64"
      />
      <path
        d="M95.7146 47.4731C95.8978 50.4805 95.852 52.2551 97.3796 53.7981C99.6774 56.1271 103.42 54.7496 104.144 51.7298C104.797 49.0244 104.389 44.5567 101.426 43.3571C100.772 43.0948 100.061 43.0019 99.3614 43.0871C98.6615 43.1723 97.9948 43.4329 97.4239 43.8443C96.853 44.2558 96.3966 44.8046 96.0977 45.4393C95.7987 46.0741 95.6669 46.7739 95.7146 47.4731Z"
        fill="#E4897B"
      />
      <path
        d="M92.1521 46.9435C92.9055 49.6862 94.6787 52.8466 96.9723 53.0493C99.8195 53.2975 101.106 50.3729 101.48 47.3531C103.187 46.1907 102.292 44.9124 104.003 44.7511C106.201 44.5442 105.497 40.5482 102.604 39.907C102.604 39.907 103.574 41.7065 101.539 40.7964C99.6029 39.8821 97.4523 39.5116 95.3198 39.725C95.3198 39.725 97.9172 41.0156 95.3781 41.5906C92.8389 42.1656 92.0813 43.721 93.251 44.6601C93.251 44.6642 91.6567 45.1399 92.1521 46.9435Z"
        fill="#263238"
      />
      <path
        d="M99.7434 47.9735C99.7993 48.721 100.102 49.4293 100.605 49.9881C101.271 50.7203 101.966 50.2652 102.083 49.3758C102.191 48.5775 101.97 47.233 101.154 46.8276C100.339 46.4222 99.6893 47.0717 99.7434 47.9735Z"
        fill="#E4897B"
      />
      <path
        d="M100.867 84.7366C100.867 84.7366 103.848 106.185 101.5 114.781C97.7786 128.391 83.551 139.879 83.551 139.879L78.7266 136.735C78.7266 136.735 90.8438 127.034 92.3256 115.638C93.6577 105.408 90.3151 93.0348 90.3151 84.7324L100.867 84.7366Z"
        fill="#5384EE"
      />
      <path
        d="M100.867 84.7366C100.867 84.7366 103.848 106.185 101.5 114.781C97.7786 128.391 83.551 139.879 83.551 139.879L78.7266 136.735C78.7266 136.735 90.8438 127.034 92.3256 115.638C93.6577 105.408 90.3151 93.0348 90.3151 84.7324L100.867 84.7366Z"
        fill="url(#paint2_linear_314_423643)"
      />
      <path
        d="M102.1 96.9976C101.307 96.0943 100.433 95.2636 99.4902 94.5156C99.9981 101.478 100.414 111.791 99.9523 119.117C100.574 117.712 101.091 116.265 101.501 114.785C102.645 110.587 102.525 103.335 102.1 96.9976Z"
        fill="#5384EE"
      />
      <path
        opacity="0.3"
        d="M102.1 96.9976C101.307 96.0943 100.433 95.2636 99.4902 94.5156C99.9981 101.478 100.414 111.791 99.9523 119.117C100.574 117.712 101.091 116.265 101.501 114.785C102.645 110.587 102.525 103.335 102.1 96.9976Z"
        fill="black"
      />
      <path
        d="M85.4962 138.808C85.517 138.808 83.6272 140.049 83.6272 140.049L78.3574 136.615L80.0224 135.246L85.4962 138.808Z"
        fill="#5384EE"
      />
      <path
        opacity="0.3"
        d="M85.4962 138.808C85.517 138.808 83.6272 140.049 83.6272 140.049L78.3574 136.615L80.0224 135.246L85.4962 138.808Z"
        fill="black"
      />
      <path
        d="M106.67 84.7366C106.67 84.7366 111.274 104.138 111.598 113.408C111.965 123.799 108.314 148.314 108.314 148.314H103.132C103.132 148.314 102.495 123.609 102.266 114.144C102.012 103.823 95.918 84.7366 95.918 84.7366H106.67Z"
        fill="#5384EE"
      />
      <path
        d="M106.67 84.7366C106.67 84.7366 111.274 104.138 111.598 113.408C111.965 123.799 108.314 148.314 108.314 148.314H103.132C103.132 148.314 102.495 123.609 102.266 114.144C102.012 103.823 95.918 84.7366 95.918 84.7366H106.67Z"
        fill="url(#paint3_linear_314_423643)"
      />
      <path
        d="M109.309 146.266C109.334 146.266 109.018 148.4 109.018 148.4H102.716L102.545 146.493L109.309 146.266Z"
        fill="#5384EE"
      />
      <path
        opacity="0.3"
        d="M109.309 146.266C109.334 146.266 109.018 148.4 109.018 148.4H102.716L102.545 146.493L109.309 146.266Z"
        fill="black"
      />
      <path
        d="M186.353 66.0928C186.707 66.4237 186.986 66.705 187.277 67.0194C187.569 67.3338 187.843 67.6399 188.11 67.9584C188.651 68.5913 189.159 69.2574 189.65 69.9275C190.631 71.2718 191.503 72.691 192.26 74.1718L192.414 74.4737C192.489 74.6199 192.552 74.7721 192.601 74.9288C192.702 75.2315 192.763 75.5456 192.784 75.8637C192.811 76.4245 192.734 76.9856 192.555 77.5184C192.251 78.4033 191.799 79.2312 191.219 79.9673C190.173 81.3022 188.939 82.4808 187.556 83.4669L186.52 82.2838C187.007 81.7171 187.514 81.109 187.968 80.5009C188.43 79.9117 188.847 79.2895 189.217 78.6394C189.562 78.0721 189.818 77.4554 189.974 76.811C190.028 76.5804 190.038 76.3419 190.004 76.1077C189.989 76.029 189.964 75.9525 189.929 75.8802C189.913 75.8485 189.895 75.8181 189.875 75.7892L189.741 75.5658C188.939 74.265 188.067 73.0071 187.131 71.7973C186.669 71.1892 186.174 70.6059 185.675 70.0309C185.429 69.7413 185.175 69.4601 184.921 69.1788C184.667 68.8975 184.392 68.6162 184.172 68.3969L186.353 66.0928Z"
        fill="#E4897B"
      />
      <path
        d="M186.918 81.9363L183.809 81.4316L185.436 84.137C185.436 84.137 187.438 84.0667 188.009 82.896L186.918 81.9363Z"
        fill="#E4897B"
      />
      <path
        d="M181.512 82.6809L182.994 84.8775L185.437 84.137L183.809 81.4316L181.512 82.6809Z"
        fill="#E4897B"
      />
      <path
        d="M186.769 152.546C186.557 152.564 186.346 152.507 186.173 152.384C186.121 152.335 186.083 152.274 186.061 152.206C186.04 152.138 186.037 152.065 186.053 151.996C186.056 151.951 186.071 151.908 186.096 151.872C186.122 151.836 186.157 151.807 186.198 151.789C186.56 151.627 187.509 152.231 187.618 152.302C187.63 152.309 187.64 152.321 187.645 152.334C187.65 152.348 187.651 152.362 187.647 152.376C187.645 152.39 187.639 152.404 187.629 152.414C187.62 152.424 187.607 152.431 187.593 152.434C187.323 152.502 187.047 152.54 186.769 152.546ZM186.352 151.905C186.322 151.9 186.291 151.9 186.261 151.905C186.242 151.913 186.227 151.926 186.216 151.943C186.205 151.96 186.199 151.98 186.198 152C186.187 152.046 186.188 152.094 186.201 152.14C186.215 152.185 186.239 152.227 186.273 152.26C186.431 152.401 186.839 152.422 187.385 152.314C187.072 152.109 186.721 151.97 186.352 151.905Z"
        fill="#5384EE"
      />
      <path
        d="M187.6 152.43H187.563C187.267 152.26 186.697 151.603 186.768 151.243C186.781 151.186 186.813 151.135 186.858 151.098C186.903 151.061 186.959 151.039 187.017 151.036C187.074 151.028 187.131 151.033 187.185 151.05C187.239 151.067 187.289 151.096 187.33 151.135C187.671 151.433 187.675 152.327 187.675 152.376C187.674 152.389 187.671 152.401 187.664 152.412C187.658 152.423 187.649 152.432 187.638 152.438L187.6 152.43ZM187.067 151.189H187.034C186.943 151.189 186.922 151.239 186.913 151.276C186.872 151.487 187.226 151.992 187.521 152.227C187.528 151.88 187.428 151.54 187.234 151.251C187.209 151.228 187.179 151.211 187.147 151.2C187.115 151.189 187.081 151.185 187.047 151.189H187.067Z"
        fill="#5384EE"
      />
      <path
        d="M172.583 152.546C172.317 152.571 172.051 152.504 171.829 152.356C171.78 152.312 171.741 152.256 171.717 152.194C171.693 152.132 171.685 152.066 171.692 152C171.695 151.96 171.708 151.921 171.729 151.887C171.751 151.853 171.781 151.825 171.817 151.805C172.204 151.59 173.445 152.219 173.586 152.298C173.599 152.306 173.609 152.318 173.616 152.332C173.623 152.345 173.625 152.361 173.624 152.376C173.621 152.391 173.614 152.404 173.604 152.414C173.593 152.425 173.58 152.432 173.565 152.434C173.242 152.502 172.913 152.54 172.583 152.546ZM172.029 151.905C171.982 151.901 171.934 151.911 171.892 151.934C171.877 151.941 171.864 151.952 171.855 151.966C171.847 151.98 171.842 151.996 171.842 152.012C171.836 152.055 171.84 152.099 171.854 152.14C171.869 152.181 171.893 152.218 171.925 152.248C172.117 152.418 172.629 152.447 173.32 152.331C172.917 152.117 172.48 151.973 172.029 151.905Z"
        fill="#5384EE"
      />
      <path
        d="M173.552 152.43H173.519C173.149 152.265 172.412 151.603 172.47 151.243C172.47 151.16 172.545 151.057 172.749 151.036C172.823 151.028 172.899 151.035 172.97 151.057C173.042 151.079 173.108 151.116 173.165 151.164C173.556 151.487 173.631 152.323 173.631 152.36C173.633 152.372 173.631 152.385 173.626 152.397C173.621 152.409 173.613 152.419 173.602 152.426C173.587 152.433 173.569 152.434 173.552 152.43ZM172.807 151.189H172.761C172.628 151.189 172.62 151.255 172.616 151.272C172.582 151.483 173.082 152.008 173.448 152.236C173.412 151.882 173.267 151.548 173.032 151.28C172.969 151.227 172.89 151.195 172.807 151.189Z"
        fill="#5384EE"
      />
      <path
        d="M178.473 56.5825C178.352 58.4523 178.027 62.2498 179.472 63.3005C179.472 63.3005 179.209 65.2158 175.8 65.6626C172.054 66.1466 173.765 64.0451 173.765 64.0451C175.75 63.2923 175.496 61.7906 174.955 60.4172L178.473 56.5825Z"
        fill="#E4897B"
      />
      <path
        d="M176.982 152.364H173.86L173.652 145.187H176.774L176.982 152.364Z"
        fill="#E4897B"
      />
      <path
        d="M191.156 152.364H188.034L187.031 145.187H190.153L191.156 152.364Z"
        fill="#E4897B"
      />
      <path
        d="M187.843 152.016H191.347C191.407 152.016 191.465 152.037 191.51 152.076C191.555 152.114 191.585 152.168 191.593 152.227L192.009 154.986C192.017 155.056 192.01 155.126 191.988 155.193C191.966 155.259 191.931 155.321 191.884 155.373C191.836 155.425 191.779 155.466 191.714 155.494C191.65 155.522 191.58 155.537 191.51 155.536C190.29 155.516 189.699 155.445 188.159 155.445C187.21 155.445 185.824 155.541 184.517 155.541C183.21 155.541 183.139 154.254 183.684 154.138C186.128 153.617 186.515 152.897 187.335 152.206C187.478 152.088 187.657 152.021 187.843 152.016Z"
        fill="#263238"
      />
      <path
        d="M173.757 152.016H177.258C177.318 152.015 177.376 152.036 177.422 152.075C177.468 152.114 177.499 152.168 177.508 152.227L177.924 154.986C177.931 155.056 177.924 155.126 177.903 155.193C177.881 155.259 177.845 155.321 177.798 155.373C177.751 155.425 177.693 155.466 177.629 155.494C177.564 155.522 177.495 155.537 177.424 155.536C176.205 155.516 175.614 155.445 174.073 155.445C173.124 155.445 171.16 155.541 169.853 155.541C168.546 155.541 168.475 154.254 169.02 154.138C171.464 153.617 172.425 152.897 173.249 152.206C173.392 152.088 173.571 152.021 173.757 152.016Z"
        fill="#263238"
      />
      <path
        d="M173.652 145.187L173.761 148.889H176.882L176.774 145.187H173.652Z"
        fill="#CE6F64"
      />
      <path
        d="M190.153 145.187H187.031L187.552 148.889H190.669L190.153 145.187Z"
        fill="#CE6F64"
      />
      <path
        d="M178.513 52.7106C178.726 55.7801 178.88 57.0748 177.539 58.8412C175.521 61.4928 171.683 61.0833 170.55 58.138C169.531 55.4863 169.356 50.9153 172.195 49.3102C172.819 48.9546 173.525 48.7633 174.245 48.7547C174.964 48.7461 175.674 48.9203 176.307 49.261C176.94 49.6016 177.475 50.0973 177.861 50.701C178.247 51.3047 178.472 51.9965 178.513 52.7106Z"
        fill="#E4897B"
      />
      <path
        d="M180.312 55.5608C182.043 56.5205 183.329 54.1543 183.329 54.1543C183.329 54.1543 180.224 54.4894 180.503 52.6527C180.869 50.2162 179.113 47.2543 174.709 47.6349C174.463 47.6556 174.23 47.6845 174.001 47.7217C173.54 47.5046 173.019 47.4485 172.522 47.5626C172.024 47.6768 171.581 47.9544 171.262 48.3505C170.013 48.4539 168.328 49.9349 169.472 51.8915C169.553 51.6667 169.677 51.46 169.839 51.2835C169.934 52.4293 170.942 54.2495 172.199 54.3694C172.469 55.9372 171.42 57.7533 171.782 59.3914C172.319 61.7534 176.328 62.2871 176.328 62.2871C176.19 61.9921 176.138 61.6643 176.18 61.3415C176.221 61.0187 176.353 60.7141 176.561 60.4628C179.283 61.7038 181.077 60.4918 181.077 60.4918C179.221 59.8051 179.783 58.5806 179.783 58.5806C180.424 58.8634 181.15 58.8883 181.809 58.6499C182.468 58.4115 183.009 57.9285 183.317 57.3024C182.7 57.3315 182.088 57.1825 181.555 56.8734C181.022 56.5644 180.59 56.1085 180.312 55.5608Z"
        fill="#263238"
      />
      <path
        d="M173.286 54.8411C173.293 55.1357 173.241 55.4288 173.134 55.7035C173.027 55.9783 172.866 56.2293 172.661 56.442C172.112 57.0087 171.437 56.6116 171.254 55.8877C171.092 55.2341 171.183 54.1544 171.899 53.8566C172.057 53.795 172.228 53.7736 172.396 53.7943C172.564 53.8149 172.724 53.877 172.861 53.9748C172.999 54.0726 173.11 54.2031 173.184 54.3544C173.258 54.5057 173.293 54.673 173.286 54.8411Z"
        fill="#E4897B"
      />
      <path
        d="M175.879 83.4382C175.879 83.4382 176.67 103.857 178.551 115.845C180.066 125.533 186.797 148.169 186.797 148.169H191.051C191.051 148.169 187.542 126.307 186.772 116.73C184.824 92.4934 189.882 87.2895 185.107 82.2427L175.879 83.4382Z"
        fill="#263238"
      />
      <path
        d="M179.434 89.8955C179.434 89.8955 177.511 89.5646 176.387 93.2049C176.803 100.101 177.515 109.251 178.551 115.845C178.78 117.314 179.13 119.084 179.559 121.024C178.747 109.375 179.434 89.8955 179.434 89.8955Z"
        fill="#161F33"
      />
      <path
        d="M172.561 83.8684C172.561 83.8684 169.057 106.459 169.261 115.911C169.473 125.744 173.098 148.178 173.098 148.178H177.315C177.315 148.178 176.649 126.328 177.02 116.672C177.436 106.145 181.761 82.6812 181.761 82.6812L172.561 83.8684Z"
        fill="#263238"
      />
      <path
        d="M191.668 148.368H186.012L185.57 146.481L191.627 146.523L191.668 148.368Z"
        fill="#5384EE"
      />
      <path
        opacity="0.2"
        d="M191.668 148.368H186.012L185.57 146.481L191.627 146.523L191.668 148.368Z"
        fill="black"
      />
      <path
        d="M184.896 63.3047C186.524 63.4619 188.996 68.0578 188.996 68.0578L184.888 71.1893C184.888 71.1893 181.487 68.2233 181.891 66.5562C182.311 64.8229 183.335 63.1599 184.896 63.3047Z"
        fill="#5384EE"
      />
      <path
        d="M184.896 63.3047C186.524 63.4619 188.996 68.0578 188.996 68.0578L184.888 71.1893C184.888 71.1893 181.487 68.2233 181.891 66.5562C182.311 64.8229 183.335 63.1599 184.896 63.3047Z"
        fill="url(#paint4_linear_314_423643)"
      />
      <path
        d="M183.668 65.282C183.253 65.1709 182.824 65.1235 182.394 65.1414C182.176 65.5936 182.007 66.068 181.891 66.5561C181.474 68.2108 184.888 71.1892 184.888 71.1892L185.192 70.9576L183.668 65.282Z"
        fill="#5384EE"
      />
      <path
        opacity="0.3"
        d="M183.668 65.282C183.253 65.1709 182.824 65.1235 182.394 65.1414C182.176 65.5936 182.007 66.068 181.891 66.5561C181.474 68.2108 184.888 71.1892 184.888 71.1892L185.192 70.9576L183.668 65.282Z"
        fill="black"
      />
      <path
        d="M171.167 69.6961C169.981 72.6787 168.732 75.7109 167.737 78.7017C167.671 78.892 167.616 79.074 167.558 79.2601L167.417 79.7193C167.378 79.8545 167.378 79.9978 167.417 80.133C167.652 80.6502 168.024 81.0936 168.495 81.4154C169.739 82.3028 171.095 83.0245 172.528 83.5623L172.154 85.0846C170.391 84.8536 168.686 84.3011 167.125 83.4548C166.165 82.9448 165.382 82.1573 164.882 81.1961C164.594 80.5818 164.494 79.8972 164.594 79.2271C164.604 79.145 164.618 79.0635 164.636 78.983L164.678 78.8092L164.748 78.4949C164.798 78.2839 164.848 78.0812 164.902 77.8744C165.319 76.2197 165.852 74.6477 166.413 73.0882C166.975 71.5286 167.562 69.9898 168.224 68.4551L171.167 69.6961Z"
        fill="#E4897B"
      />
      <path
        d="M168.641 65.5095C168.641 65.5095 167.234 66.2169 172.562 83.8682L185.124 82.2383C184.242 77.1833 183.826 74.0518 184.895 63.3046C183.094 63.1764 181.286 63.1764 179.484 63.3046C177.566 63.4155 175.659 63.6643 173.777 64.0493C171.367 64.5829 168.641 65.5095 168.641 65.5095Z"
        fill="#5384EE"
      />
      <path
        d="M168.641 65.5095C168.641 65.5095 167.234 66.2169 172.562 83.8682L185.124 82.2383C184.242 77.1833 183.826 74.0518 184.895 63.3046C183.094 63.1764 181.286 63.1764 179.484 63.3046C177.566 63.4155 175.659 63.6643 173.777 64.0493C171.367 64.5829 168.641 65.5095 168.641 65.5095Z"
        fill="url(#paint5_linear_314_423643)"
      />
      <path
        d="M185.215 81.4814L185.936 82.5197C185.99 82.6024 185.898 82.7017 185.757 82.7224L172.582 84.435C172.47 84.435 172.366 84.406 172.349 84.3357L172.091 83.2312C172.07 83.1567 172.162 83.0781 172.286 83.0616L185.007 81.4069C185.045 81.4008 185.085 81.4045 185.121 81.4175C185.157 81.4305 185.19 81.4525 185.215 81.4814Z"
        fill="#5384EE"
      />
      <path
        opacity="0.2"
        d="M185.215 81.4814L185.936 82.5197C185.99 82.6024 185.898 82.7017 185.757 82.7224L172.582 84.435C172.47 84.435 172.366 84.406 172.349 84.3357L172.091 83.2312C172.07 83.1567 172.162 83.0781 172.286 83.0616L185.007 81.4069C185.045 81.4008 185.085 81.4045 185.121 81.4175C185.157 81.4305 185.19 81.4525 185.215 81.4814Z"
        fill="black"
      />
      <path
        d="M183.692 83.1153L184.034 83.0698C184.1 83.0698 184.146 83.0202 184.138 82.9788L183.788 81.5475C183.775 81.5231 183.754 81.504 183.728 81.4934C183.702 81.4829 183.673 81.4815 183.647 81.4896L183.305 81.531C183.235 81.531 183.189 81.5806 183.201 81.6261L183.551 83.0533C183.559 83.0988 183.626 83.1236 183.692 83.1153Z"
        fill="#263238"
      />
      <path
        d="M175.435 84.1744L175.776 84.1289C175.842 84.1289 175.892 84.0793 175.88 84.0379L175.53 82.6066C175.517 82.5825 175.495 82.5634 175.47 82.5522C175.444 82.541 175.416 82.5383 175.389 82.5446L175.047 82.5901C174.981 82.5901 174.931 82.6397 174.943 82.6811L175.293 84.1124C175.301 84.1744 175.368 84.1992 175.435 84.1744Z"
        fill="#263238"
      />
      <path
        d="M169.227 71.4995C169.572 73.0962 170.059 75.1191 170.771 77.6797L170.925 71.1892L169.227 71.4995Z"
        fill="#5384EE"
      />
      <path
        opacity="0.3"
        d="M169.227 71.4995C169.572 73.0962 170.059 75.1191 170.771 77.6797L170.925 71.1892L169.227 71.4995Z"
        fill="black"
      />
      <path
        d="M168.64 65.5095C167.079 66.1341 165.727 71.4415 165.727 71.4415L171.096 74.0393C171.096 74.0393 173.69 67.5489 172.528 66.283C171.367 65.0172 170.439 64.7938 168.64 65.5095Z"
        fill="#5384EE"
      />
      <path
        d="M168.64 65.5095C167.079 66.1341 165.727 71.4415 165.727 71.4415L171.096 74.0393C171.096 74.0393 173.69 67.5489 172.528 66.283C171.367 65.0172 170.439 64.7938 168.64 65.5095Z"
        fill="url(#paint6_linear_314_423643)"
      />
      <path
        d="M178.036 148.368H172.379L171.938 146.481L178.235 146.523L178.036 148.368Z"
        fill="#5384EE"
      />
      <path
        opacity="0.2"
        d="M178.036 148.368H172.379L171.938 146.481L178.235 146.523L178.036 148.368Z"
        fill="black"
      />
      <path
        d="M172.616 83.0491L172.162 82.863C172.051 82.8179 171.929 82.8029 171.81 82.8196C171.691 82.8363 171.578 82.884 171.484 82.958C171.389 83.0319 171.316 83.1294 171.271 83.2406C171.227 83.3518 171.212 83.4727 171.23 83.591L171.559 85.7462C171.575 85.8541 171.614 85.9572 171.675 86.0479C171.736 86.1387 171.816 86.2149 171.91 86.2711C172.004 86.3273 172.109 86.3621 172.219 86.3729C172.328 86.3837 172.438 86.3703 172.541 86.3337C172.706 86.2741 172.861 86.1889 172.999 86.0813C173.15 85.9572 173.269 85.7998 173.347 85.6216C173.425 85.4434 173.46 85.2495 173.449 85.0554L173.403 84.1619C173.395 83.9204 173.316 83.6865 173.177 83.4889C173.037 83.2912 172.842 83.1384 172.616 83.0491Z"
        fill="#E4897B"
      />
      <defs>
        <linearGradient
          id="paint0_linear_314_423643"
          x1="140"
          y1="0.282323"
          x2="140"
          y2="145"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DCE9FC" />
          <stop offset="1" stopColor="#DCE9FC" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_314_423643"
          x1="77.4311"
          y1="32.8978"
          x2="90.9936"
          y2="110.012"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4F8AED" />
          <stop offset="0.4327" stopColor="#5384EE" />
          <stop offset="0.8061" stopColor="#5159C0" />
          <stop offset="1" stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_314_423643"
          x1="77.6199"
          y1="93.1413"
          x2="103.87"
          y2="136.555"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4F8AED" />
          <stop offset="0.4327" stopColor="#5384EE" />
          <stop offset="0.8061" stopColor="#5159C0" />
          <stop offset="1" stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_314_423643"
          x1="95.1838"
          y1="94.431"
          x2="129.317"
          y2="126.915"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4F8AED" />
          <stop offset="0.4327" stopColor="#5384EE" />
          <stop offset="0.8061" stopColor="#5159C0" />
          <stop offset="1" stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_314_423643"
          x1="181.524"
          y1="64.4995"
          x2="183.774"
          y2="72.3396"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4F8AED" />
          <stop offset="0.4327" stopColor="#5384EE" />
          <stop offset="0.8061" stopColor="#5159C0" />
          <stop offset="1" stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_314_423643"
          x1="167.694"
          y1="66.3588"
          x2="174.175"
          y2="86.4821"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4F8AED" />
          <stop offset="0.4327" stopColor="#5384EE" />
          <stop offset="0.8061" stopColor="#5159C0" />
          <stop offset="1" stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_314_423643"
          x1="165.395"
          y1="66.4681"
          x2="168.229"
          y2="75.1466"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4F8AED" />
          <stop offset="0.4327" stopColor="#5384EE" />
          <stop offset="0.8061" stopColor="#5159C0" />
          <stop offset="1" stopColor="#626DF0" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default EmptySearchSvg;
